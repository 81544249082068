import { TPropsHomePage } from '@/screens/HomePage/domain/HomePage.domain';
import { GetStaticProps } from 'next';
import { getBlogsList, getPopularDestinations } from '../src/screens/HomePage/service/ApiHomePage';
import { HomePage } from '../src/screens/HomePage/ui/HomePage';
import { fetchAllLandmarks } from '@/screens/LandmarkMainPage/service/fetchAllLandmarks';
import { mapLandmarksForWidget } from '@/screens/HomePage/service/mapLandmarksForWidget';
import { fetchRecentReviews } from '@/entities/Reviews/service/fetchReviews';
import dateless from '../scripts/cache/dateless.json';
import { TDestinationItem } from '@/common/service/api/Destination/Destination.domain';

export const getStaticProps: GetStaticProps<TPropsHomePage> = async () => {
    const [blogs, landmarksRes, popularDestinations, recentReviews] = await Promise.all([
        getBlogsList(4),
        fetchAllLandmarks({ featured: 1 }),
        getPopularDestinations(),
        fetchRecentReviews({
            w_min: 25,
            w_max: 65,
        }),
    ]);

    const landmarks = mapLandmarksForWidget(landmarksRes);

    const newPopularDestinations = () => {
        const topDestinationsIds = ['12', '59', '60', '80', '16', '41', '20', '38'];

        const popular: TDestinationItem[] = [];
        const rest: TDestinationItem[] = [];

        popularDestinations.forEach(destination => {
            if (destination.experiences === '0 experience') {
                return;
            }

            const popularIndex = topDestinationsIds.indexOf(destination.id);

            if (popularIndex >= 0) {
                popular[popularIndex] = destination;
            } else {
                rest.push(destination);
            }
        });

        const topDestinations = popular.flat();

        const otherDestinations = rest
            .sort((a, b) => +b.experiences.split(' ')[0] - +a.experiences.split(' ')[0]);

        return [...topDestinations, ...otherDestinations];
    };

    const tripNames: {
        [key: string]: string;
    } = Object.values(
        dateless as {
            [key: string]: {
                items: { id: string; name: string }[];
            };
        }
    ).reduce((acc: { [key: string]: string }, curr) => {
        const newTrips: {
            [key: string]: string;
        } = {};
        curr.items
            .filter((i: { id: string; name: string }) => !acc[i.id])
            .forEach(({ id, name }: { id: string; name: string }) => {
                newTrips[id] = name;
            });

        return {
            ...acc,
            ...newTrips,
        };
    }, {});
    const normaliseRecentReviews = (recentReviews || [])
        .map((i) => ({
            ...i,
            trip_name: tripNames[i.trip_id] || '',
        }))
        .filter((i) => !!i.trip_name);

    return {
        props: {
            blogs,
            landmarks,
            popularDestinations: newPopularDestinations(),
            recentReviews: normaliseRecentReviews,
        },
    };
};

export default HomePage;
