export const numberWithCommaFormatter = (digit: number): string => {
    return digit.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const numberFormatDecimals = (digits: number): string =>
    digits.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const numberFormatPrice = (price: number, options?: Intl.NumberFormatOptions) =>
    new Intl.NumberFormat('en-IN', options).format(price);

export function formatReviewsCount(str?: string) {
    if (!str) return '';
    try {
        const num = parseFloat(str);
        if (num < 1000) return str;
        const truncated = Math.floor((num / 1000) * 10) / 10;
        return truncated + 'K';
    } catch (error) {
        return '';
    }
}

export function formatReviewsRating(str?: string) {
    if (!str || str === '0') return '';
    try {
        const num = parseFloat(str);
        return num.toFixed(1);
    } catch (error) {
        return '';
    }
}
