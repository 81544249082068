import { ReactElement } from 'react';
import * as S from './HomePageLandmarks.styles';
import { TLandmarkWidget } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import { LandmarkCard } from '@/screens/LandmarkMainPage/ui/LandmarkCard';
import Link from 'next/link';

type TopLandmarksProps = {
    landmarks: TLandmarkWidget[];
};

const BLOCK_SIZE = 3;

export const HomePageLandmarks = ({ landmarks }: TopLandmarksProps): ReactElement => {
    if (!landmarks || !landmarks.length) {
        return <></>;
    }

    const firstSet = landmarks.slice(0, BLOCK_SIZE);
    const secondSet = landmarks.slice(BLOCK_SIZE, BLOCK_SIZE * 2);

    return (
        <S.Container>
            <S.Title>{`Tripshock's Top Landmarks`}</S.Title>
            <S.GridContainer>
                <S.Grid half={!secondSet.length}>
                    {firstSet.map((landmark) => (
                        <LandmarkCard item={landmark} key={landmark.id} noMinHeight />
                    ))}
                </S.Grid>
                {!!secondSet.length && (
                    <S.Grid reverse>
                        {secondSet.map((landmark) => (
                            <LandmarkCard item={landmark} key={landmark.id} noMinHeight />
                        ))}
                    </S.Grid>
                )}
            </S.GridContainer>
            {landmarks.length > 1 && (
                <Link href="/landmarks/" passHref>
                    <S.MoreButton
                        variant="primary"
                        styles={{ maxWidth: '274px' }}
                        onClick={() => {
                            return;
                        }}
                    >
                        More Landmark Guides
                    </S.MoreButton>
                </Link>
            )}
        </S.Container>
    );
};
